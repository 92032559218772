// 消息类型
export const MESSAGE_TYPE = {
  Text: 'text',
  Image: 'image',
  Voice: 'audio',
  Video: 'video',
  File: 'file',
  Reply: 'reply'
}

export const baseUrl = process.env.VUE_APP_BASE_URL
export const headers = {
  token: localStorage.getItem('token')
}

/**
 * 生成唯一ID
 * @param prefix
 * @return {string}
 */
export function uuid(prefix = 'id') {
  return `${prefix}_${Math.random().toString(36).substr(2)}`
}

/**
 * 账号状态
 */
export const ACCOUNT_STATUS = [
  {
    label: '正常',
    value: 'OK'
  },
  {
    label: '封号',
    value: 'bad'
  },
  {
    label: '未知封控',
    value: 'unknown'
  },
  {
    label: '垃圾账号',
    value: 'spam'
  },
  {
    label: '可疑行为',
    value: 'integrity'
  },
  {
    label: '关联异常',
    value: 'associated'
  }
]